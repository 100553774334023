import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
  obtener_catalogos() {
    return http.get(`/catalogos`);
  }

  ,obtener_catalogo(id) {
    return http.get(`/catalogos/${id}`);
  }

  ,buscar_catalogo(codigo) {
    return http.get(`/catalogos/buscar/${codigo}`);
  }

  ,crear_catalogo(payload) {
    return http.post(`/catalogos`,payload);
  }

  ,editar_catalogo(id, payload) {
    return http.put(`/catalogos/${id}`, payload);
  }

  ,eliminar_catalogo(id) {
    return http.delete(`/catalogos/${id}`);
  }
}