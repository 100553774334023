<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">{{ !nueva_revision.id ? 'Nueva' : 'Editar' }} revisión</div>
      <div class="body">
        <div class="row form-group">
          <label for="tipo" class="col-form-label col-sm-3">Tipo</label>
          <div class="col-sm-9">
            <select v-model="nueva_revision.tipo" name="tipo" id="tipo" class="form-control">
              <option v-for="opcion in catalogo.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
          <div class="col-sm-9">
            <textarea v-model="nueva_revision.descripcion" name="descripcion" id="descripcion" class="form-control"></textarea>
          </div>
        </div>
        <div v-if="nueva_revision.id" class="row form-group">
          <label for="comentarios_finales" class="col-form-label col-sm-3">Comentarios finales</label>
          <div class="col-sm-9">
            <textarea v-model="nueva_revision.comentarios_finales" name="comentarios_finales" id="comentarios_finales" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="nueva_revision.id" class="btn btn-success mr-2" @click="modal_resolver=true;">Resolver revisión</button>
            <button class="btn btn-primary mr-2" @click="guardar">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal_resolver" :options="{width: '30vw',type:'danger'}" @close="modal_resolver=false">
      <div class="title">Resolver revisión</div>
      <div class="body">
        <p class="text-center">Al resolver la revisión no podrás volver a editar<br/>¿Deseas continuar?</p>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="resolver_revision">Cerrar revisión</button>
            <button class="btn btn-danger" @click="modal_resolver=false">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,revision: {
        type: Object
        ,default() {
          return {
            id: null
            ,descripcion: null
            ,comentarios_finales: null
          }
        }
      }
    }
    ,data() {
      return {
        nueva_revision: {
          tipo: null
          ,descripcion: null
          ,comentarios_finales: null
          ,estatus: 1
        }
        ,catalogo: {
          opciones: []
        }
        ,modal_resolver: false
      }
    }
    ,mounted() {
      this.nueva_revision = this.$helper.clone(this.revision);
      this.$log.info('nueva_revision', this.nueva_revision);
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_catalogos() {
        try {
          this.catalogo = await this.$helper.cache_store('formalizacion.catalogos.revisiones', async () => {
            return (await apiCatalogo.buscar_catalogo('revisiones')).data;
          });

          if (!this.nueva_revision.tipo)
            this.nueva_revision.tipo = this.catalogo.opciones[0].valor;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async guardar() {
        try {
          let res;

          if (!this.nueva_revision.id)
            res = (await apiSolicitudes.crear_revision(this.solicitud.id, this.nueva_revision)).data;
          else
            res = (await apiSolicitudes.editar_revision(this.solicitud.id, this.nueva_revision.id, this.nueva_revision)).data;

          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async resolver_revision() {
        try {
          this.nueva_revision.estatus = 2;

          let res = (await apiSolicitudes.editar_revision(this.solicitud.id, this.nueva_revision.id, this.nueva_revision)).data;

          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
  }
</style>